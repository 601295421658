import $ from 'jquery'
import gsap from 'gsap'

const navWrapper = $('[data-id="nav-wrapper"]')
const dropdownWrapper = $('[data-id="dropdown-wrapper"]')
const contentWrapper = $('[data-id="content-wrapper"]')
const navItem = $('[data-id="nav-item"]')
const arrow = $('[data-id="dropdown-arrow"]')
const background = $('[data-id="dropdown-bg"]')
const content = $('[data-id="content-group"]')
const contentItem = $('[data-id="content-item"]')

const ANIMATION_DURATION = 0.2
const EVENTS = {
  MOUSE_ENTER: 'mouseenter',
  MOUSE_LEAVE: 'mouseleave',
  MOUSE_MOVE: 'mousemove',
  FOCUS: 'focus',
  BLUR: 'blur',
  KEYDOWN: 'keydown',
}
const KEYS = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_UP: 'ArrowUp',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_DOWN: 'ArrowDown',
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  TAB: 'Tab',
}

const showDropdown = createShowDropdownTimeline()

function createShowDropdownTimeline() {
  return gsap
    .timeline({
      onReverseComplete: () => {
        navItem.removeClass('active')
        navItem.attr('aria-expanded', false)
        dropdownWrapper.css('display', 'none')
      },
    })
    .from(dropdownWrapper, { opacity: 0, rotateX: -15, duration: ANIMATION_DURATION })
    .to(arrow, { opacity: 1, duration: ANIMATION_DURATION }, '<')
}

function getNavItemCenter(navItem) {
  return navItem.position().left + navItem.width() / 2
}

function getCurrentContent(currentNavItem) {
  return content.eq(currentNavItem.index())
}

function switchDropdown(currentNavItem, previousContent, currentContent) {
  let moveDistance = 10

  previousContent.css('display', 'none')
  currentContent.css('display', 'flex')

  if (currentContent.index() < previousContent.index()) {
    moveDistance *= -1
  }

  gsap.to(arrow, {
    x: getNavItemCenter(currentNavItem),
    duration: ANIMATION_DURATION,
  })

  gsap.to([background, contentWrapper], {
    width: currentContent.outerWidth(),
    height: currentContent.outerHeight(),
    duration: ANIMATION_DURATION,
  })

  gsap.fromTo(
    previousContent,
    { opacity: 1, x: '0em' },
    {
      opacity: 0,
      x: -1 * (moveDistance + 'em'),
      duration: ANIMATION_DURATION,
    }
  )

  gsap.fromTo(
    currentContent,
    { opacity: 0, x: moveDistance + 'em' },
    {
      opacity: 1,
      x: '0em',
      duration: ANIMATION_DURATION,
    }
  )
}

function revealDropdown(currentNavItem, currentContent) {
  content.css('display', 'none') // Reset all displays to none to guarantee menus do not overlap
  currentContent.css('display', 'flex')
  dropdownWrapper.css('display', 'flex')

  showDropdown.restart()

  gsap.set(arrow, {
    x: getNavItemCenter(currentNavItem),
  })

  gsap.from(arrow, {
    y: 20,
    duration: ANIMATION_DURATION,
  })

  gsap.set(background, {
    width: currentContent.outerWidth(),
    height: currentContent.outerHeight(),
  })

  gsap.set(contentWrapper, {
    width: currentContent.outerWidth(),
    height: currentContent.outerHeight(),
  })

  gsap.set(content, {
    opacity: 0,
  })

  gsap.set(currentContent, {
    opacity: 1,
    x: '0em',
  })
}

function handleNavItemActivity() {
  if ($(this).hasClass('active')) return

  const previousNavItem = navItem.filter('.active')
  const previousContent = content.filter('.active')

  previousNavItem.attr('aria-expanded', false)
  navItem.removeClass('active')
  content.removeClass('active')

  const currentNavItem = $(this).addClass('active').attr('aria-expanded', true)
  const currentContent = getCurrentContent($(this)).addClass('active')

  if (previousNavItem.length > 0) {
    switchDropdown(currentNavItem, previousContent, currentContent)
  } else {
    revealDropdown(currentNavItem, currentContent)
  }
}

function handleBlur(e) {
  const nextElementDataId = e.relatedTarget?.getAttribute('data-id')

  if (contentItem.is(e.relatedTarget)) return

  navItem.removeClass('active')
  showDropdown.reverse()
}

function handleNavItemKeydown({ key }) {
  switch (key) {
    case KEYS.ENTER:
      handleNavItemActivity.apply(this)
      const currentContentItems = getCurrentContent($(this)).find(contentItem)
      currentContentItems.first().focus()
      break

    case KEYS.ARROW_DOWN:
    case KEYS.ARROW_RIGHT:
      handleNavItemActivity.apply(this)
      break
  }
}

function handleNavWrapperKeydown(e) {
  const activeNavItem = navItem.filter('.active')
  const currentContentItems = getCurrentContent(activeNavItem).find(contentItem)
  const focusedElement = currentContentItems.filter(':focus')
  const focusIndex = currentContentItems.index(focusedElement)
  const noContentItemsFocused = focusIndex === -1

  switch (e.key) {
    case KEYS.TAB:
      if (noContentItemsFocused) return
      activeNavItem.focus()
      break

    case KEYS.ARROW_LEFT:
    case KEYS.ARROW_RIGHT:
    case KEYS.ARROW_DOWN:
    case KEYS.ARROW_UP:
      e.preventDefault()

      const direction = [KEYS.ARROW_DOWN, KEYS.ARROW_RIGHT].includes(e.key) ? 1 : -1
      const nextIndex = wrapInteger(focusIndex + direction, 0, currentContentItems.length)
      currentContentItems[nextIndex].focus()
      break

    case KEYS.ESCAPE:
      activeNavItem.first().focus()
      activeNavItem.trigger('blur')
      break
  }
}

function wrapInteger(value, min, max) {
  const range = max - min
  return ((((value - min) % range) + range) % range) + min
}

// Listeners
let mouseLeaveTimeout

navWrapper.on(EVENTS.MOUSE_ENTER, () => {
  clearTimeout(mouseLeaveTimeout)
})

navWrapper.on(EVENTS.MOUSE_LEAVE, () => {
  mouseLeaveTimeout = setTimeout(() => {
    showDropdown.reverse()
  }, 200)
})

navWrapper.on(EVENTS.KEYDOWN, handleNavWrapperKeydown)

navItem.on(EVENTS.MOUSE_MOVE, handleNavItemActivity)

navItem.on(EVENTS.FOCUS, function () {
  clearTimeout(mouseLeaveTimeout)
})

navItem.on(EVENTS.KEYDOWN, handleNavItemKeydown)

navItem.on(EVENTS.BLUR, handleBlur)

contentItem.on(EVENTS.BLUR, handleBlur)
